<template>
  <div>
  
      <badge :type="getBadgeType()">
        {{ $t(`CASH_REGISTER_READINGS.STATUS_${cashRegisterReading.status}`) }}
      </badge>

  </div>
</template>

<script>
import {
  CASH_REGISTER_READING_STATUS_DRAFT,
  CASH_REGISTER_READING_STATUS_CANCELED,
  CASH_REGISTER_READING_STATUS_VALIDATED,
} from "@/constants/cashRegisterReading";

export default {
  name: "cash-register-reading-status-badge",

  components: {},

  mixins: [],

  props: ["cashRegisterReading"],

  data() {
    return {
  
    };
  },

  computed: {},

  methods: {
    getBadgeType() {
      switch (this.cashRegisterReading.status) {
        case CASH_REGISTER_READING_STATUS_DRAFT:
          return "primary";
        case CASH_REGISTER_READING_STATUS_CANCELED:
          return "danger";
        case CASH_REGISTER_READING_STATUS_VALIDATED:
          return "success";
        default:
          break;
      }
      return "default";
    },

  
  },

  mounted() {},

  watch: {},
};
</script>
